@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@300;400;500;600;700&family=Racing+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@300;400;500;600;700&family=Gothic+A1:wght@400;500;600;700&family=Racing+Sans+One&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  /* overflow: -moz-scrollbars-vertical; */
overflow-x: hidden;
background:#506c82;
margin: 0;
padding: 0!important;
overflow-y: auto !important;
font-family: 'Gothic A1', sans-serif;
}

body::-webkit-scrollbar{
  width: 5px;
}

body::-webkit-scrollbar-thumb{
  background: rgb(240, 176, 24);
  border-radius: 10px;
}

body::-webkit-scrollbar-track{
  background: #bdc7ee;
  border-radius: 10px;
}


/* ########## Header sTyling ############# */

.RacingFont{
  font-family: 'Racing Sans One', cursive;
}

.logo{
  z-index: 99999;
}

  .topHeaderbar{
    background-color: rgba(0, 0, 0, 0.842) !important;
    transition: 0.5s;
    height: 100%;
  }

  .navbar-toggler {
    width: 60px;
    height:60px;
    position: relative;
    transition: .5 ease-in-out;
    border:none;
    z-index: 9999;
  }

  /* .navbar-toggler span {
     margin:0;
     padding: 0;
  } */

  .toggler-icon{
    display:block;
    position: absolute;
    height : 2px;
    /* width :100%; */
    background:rgb(240, 176, 24);
    border-radius: 1px;
    border:none;
    transform: rotate(0deg);
    transition:.25s ease-in-out;
    
  }

  .navbar-toggler,
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus{
    border:0;
    outline: none;
    box-shadow: none;
  }

   .navbar-toggler .top-bar{
    opacity: 1;
    /* margin-bottom: 20px !important; */
    width:50px;
    transform: rotate(135deg);
  }

  .middle-bar{
    margin-top: 0px;
  }

  .navbar-toggler .middle-bar{
    opacity: 0;
    /* margin-bottom: 20px !important; */
    /* margin-top: 20px !important; */
    width:35px;
    filter: alpha(opacity=0);
  }
  .navbar-toggler .bottom-bar{
    opacity: 1;
    /* margin-bottom: 20px !important; */
    margin-top: 0px;
    width:50px;
    transform: rotate(-135deg);
  }

  .collapsed{
    margin-top: -20px !important;
    transform: rotate(0deg)!important;
  }

  .collapsed{
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }

  .collapsed{
    margin-top: 20px !important;
    transform: rotate(0deg)!important;
  }
  
  .close_button{
    /* color: 2px solid rgb(240, 176, 24) !important; */
    /* background-color: azure; */
    /* border: 2px solid rgb(240, 176, 24); */
    border-radius: 5px;
    margin-right: 1px !important;
    margin-top: 10px !important;
    width:35px !important;
    height:35px !important;
  }

  .menuStyle{
    color:rgb(240, 176, 24);
    margin-top: 80px;
    font-size: 35px;
    font-weight: 900;
    text-transform: uppercase;
  }
  .menuStyle ul li{
    list-style: none;
  }
.linkStyles{
  text-decoration: none;
  color:rgb(240, 176, 24);
}
.linkStyles:hover{
  text-decoration: none;
  color:rgb(218, 158, 18);
}

/* ######### Slider Styling########### */

  #introCarousel,
  .carousel-inner,
  .carousel-item,
  .carousel-item.active {
    height: 90vh;
  }

  .HeaderCarousel .carousel-item:nth-child(1) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../src/Images/cloud-storage-banner-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .HeaderCarousel .carousel-item:nth-child(2) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../src/Images/imgpsh_fullsize_anim.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .HeaderCarousel .carousel-item:nth-child(3) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../src/Images/futuristic-smart-city-with-5g-global-network-technology.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    
  }

  .carousel-control-prev-icon {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.carousel-control-next-icon {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.carousel-control-prev-icon {

  background-image: url("../src/Images/Left\ ScrollArrow.svg");
  width:60px;
  height: 60px;
}

.carousel-control-next-icon {

  background-image: url("../src/Images/Right\ ScrollArrow.svg");
  width:60px;
  height: 60px;
}

.carousel-indicators [data-bs-target] {
  width: 15px;
  height: 15px;
  border-radius: 10%;
  background-color:rgb(240, 176, 24);
}

  /* Height for devices larger than 576px */
  @media (min-width: 992px) {
    #introCarousel {
      margin-top: -58.59px;
    }
  }

  .navbar .nav-link {
    color: #fff !important;
  }

.Slider-Text{
  top: 40%;
  left: 20%;
  position: absolute;
}

.textGoldColor{
  color:rgb(240, 176, 24);
}

.imgoverlays1{
  background-image: url('../src/Images/square.svg');
  position: absolute;
  width: 20%;
  height: 140px;
  top: 30%;
  left: 5%;
  filter: brightness(60%);
  background-repeat: no-repeat;
}
.imgoverlays2{
  background-image: url('../src/Images/square.svg');
  position: absolute;
  width: 20%;
  height: 140px;
  top: 73%;
  left: 30%;
  background-repeat: no-repeat;
}
.imgoverlays3{
  background-image: url('../src/Images/square.svg');
  position: absolute;
  width: 20%;
  height: 140px;
  top: 65%;
  left: 90%;
  background-repeat: no-repeat;
}
.imgoverlays4{
  background-image: url('../src/Images/square.svg');
  position: absolute;
  width: 20%;
  height: 140px;
  top: 15%;
  left: 90%;
  background-repeat: no-repeat;
}

/* ############ End Header #############  */

/* #f0b018 */
/* RGB(240, 176, 24) */

/* ############## Home Page ##########  */
/* Service */
.airforceBlue{
  background:#506c82;
  overflow-x: hidden;
}

.InfinityImage img{
  /* background-image: url('../src/Images/Infinity.svg'); */
  /* background-size: 10px 20px; */
  position: absolute;
  max-width: 15%;
  height: auto;
  top: 0%;
  left: 2%;
  background-repeat: no-repeat;
}

.imgoverlays5 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 15%;
  height: auto;
  top: 0%;
  right: -7%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}

.borderOnLeft{
  border-left: 4px solid #fff;
  border-radius: 2px;
  height: 80px;
  margin: 0 5px;
  z-index: 1;
}

.hearderServices{
  font-weight: 500;
  color: #fff;
}

.ServicesList ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ServicesList li {
  float: left;
}

.ServicesList li a {
  display: block;
  color: rgb(240, 176, 24);
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  padding: 0 5px;
  margin-right: 10px;
  text-decoration: none;
  font-family: 'Antonio', sans-serif;
  /* height:10px; */
}

.vl{
  border-right: 2px solid #fff;
  height: 20px;
}

.manageddiv{
  height: 9rem;
  margin: 10px 0;
}

.smallText{
  font-size: 13px;
}

.col-padd{
padding: 0 2px !important;
}

.card {
  min-height: 350px;
}

/* .b{
  border: 2px solid darkslategrey;
} */

.textb > span{
  font-weight: 600;
  font-size: 16px;
}

.textb{
  font-weight: 800;
  font-size: 17px;
  padding: 0;
  margin:2px;
}

.ImageDivSmall{
  background-image: url('../src/Images/square.svg');
  /* position: absolute; */
  width: 50%;
  height: auto;
  color: rgb(182, 139, 41);
  background-repeat: no-repeat;
}

.textEb{
  font-weight: 800;
  font-size: 20px;
  padding: 0;
  margin:2px;
}

.WIdth80{
  width:85%;
}


.borderdallSides{
  height: 280px;
  border: 12px solid #f6d576;
  margin: 15px;
}

.BackgroundGold{
  background-color: rgb(240, 176, 24);
}

.arrow{
  background-repeat: no-repeat;
  width: 40px;
height: 40px;
background-image: url('../src/Images/Black\ Right\ Arrow.svg');
}


.ReadMore,
  .ReadMore:focus,
  .ReadMore:active{
    border:0;
    outline: none;
    box-shadow: none;
    text-decoration: none;
    color:rgba(0, 0, 0, 0.5);
  }

  .ReadMore:hover{
    color:rgba(0, 0, 0, 0.836);
  }

  /* About  */

.InfinityImageR img{
  /* background-image: url('../src/Images/colorInfinity.png'); */
  position: absolute;
  width: 35%;
  height: auto;
  top: 0%;
  right: 65%;
  background-repeat: no-repeat;
}


.imgoverlays6 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 20%;
  height: auto;
  top: 0%;
  Right: -10%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
  transform: rotate(45deg);
}

.about-Us{
  padding: 0 0 0 330px;
  color:#fff;
}

.Trans{
  color:rgb(240, 176, 24);
  font-size: 35px;
}

.arrowG{
  background-repeat: no-repeat;
  width: 50px;
height: 60px;
background-image: url('../src/Images/Right\ Arrow.svg');
}

.ReadMoreG,
  .ReadMoreG:focus,
  .ReadMoreG:active{
    border:0;
    outline: none;
    box-shadow: none;
    text-decoration: none;
    color:rgb(240, 176, 24);
    font-size: 20px;
  }

  .ReadMoreG:hover{
    color:rgb(185, 130, 0);
  }

/* Customers  */

.imgoverlays7 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  width: 17%;
  height: auto;
  top: 15%;
  left: 12%;
  /* clip: rect(0px,68px,140px,0px); */
  /* overflow-x: hidden; */
  background-repeat: no-repeat;
  /* transform: rotate(45deg); */
}

.imgoverlaysGroup img{
  /* background-image: url('../src/Images/3qubeswithimage-01.svg'); */
  position: absolute;
  max-width: 25%;
  height: auto;
  top: 0;
  left: 75%;
  /* clip: rect(0px,68px,140px,0px); */
  /* overflow-x: hidden; */
  background-repeat: no-repeat;
  /* transform: rotate(45deg); */
}

.customers{
  /* overflow-y: hidden; */
}

/* ### */

/* .CarsCus .cards-wrapper {
  display: flex;
  justify-content: center;
}
.CarsCus .card img {
  max-width: 100%;
  max-height: 100%;
}
.CarsCus .card {
  margin: 0 0.5em;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  border: none;
  border-radius: 0;
}
.CarsCus .carousel-inner {
  padding: 1em;
}
.CarsCus .carousel-control-prev,
.CarsCus .carousel-control-next {
  background-color: #e1e1e1;
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .CarsCus .card img {
    height: 11em;
  }
}
 */

 .airforceBlueGrad{
  background: rgb(255,255,255);
background: linear-gradient(0deg, rgba(255,255,255,1) 13%, rgba(80,108,130,1) 100%);
 }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  /* border:2px solid rgb(240, 176, 24); */
  border-radius: 2px;
}

.swiper-slide img {
  display: block;
  width: 110px;
  height: 180px;
  object-fit: contain;
 
}

.swiper {
  width: 100%;
  height: 100%;
  margin: 10px auto;
}
.append-buttons {
  text-align: center;
  margin-top: 10px;
  /* position: absolute; */
}

.append-buttons button{
  /* display: inline-block; */
  cursor: pointer;
  /* border: 1px solid #007aff;
  color: #007aff; */
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px;
}

.swiper-button-prev,
.swiper-button-next{
  color:  rgb(240, 176, 24) !important;
}

:root {
  --swiper-theme-color:rgb(240, 176, 24) !important;
}


/* .swiper-wrapper{
  position: relative;
} */

/* .swiper-button-prev{
  background-image:url('../src/Images/Left\ ScrollArrow.svg');
  position: absolute;
  background-repeat: no-repeat;
  width:100px;
  height: 100px;
  top:50%;
  left: 5% !important;
}
.swiper-button-next{
  background-image:url('../src/Images/Right\ ScrollArrow.svg');
  position: absolute;
  background-repeat: no-repeat;
  width:100px;
  height: 100px;
  top:50%;
  right: 5% !important;
} */

.bg{
  background-color: rgba(128, 128, 128, 0.856);
  background-image: url('../src/Images/Footer\ Image_1.svg');
  min-height: 60vh ;
  /* background-repeat: repeat-x; */
  /* height: 100vh; */

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 10;
}

.footer{
 
  /* object-fit: contain; */
  
  width:100%;
  min-height: 40vh ;
}

.footerList ol li{
  color: #ffffff;
  font-size: 14px;
  display: inline-block;
  padding-left: 18px;
}

.footerList a{
  text-decoration: none;
  color: #ffffffe0;
}

.address img{
  height: 25px;
  width: 35px;
}
.address span{
  font-size: 16px;
}

.Email img{
  height: 25px;
  width: 25px;
  margin: 0 0 0 5px;
}
.Email span{
  font-size: 16px;
}

.Contact img{
  height: 25px;
  width: 35px;
}


.Contact span{
  font-size: 16px;
}


/* ################# */

.Slider-TextSD{
  top: 26%;
  left: 38%;
  position: absolute;
}
.ShippingAndDelivery{
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../src/Images/shipping.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 60vh;
}
.Slider-TextCR{
  top: 26%;
  left: 38%;
  position: absolute;
}
.CancellationsAndRefund{
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../src/Images/Refundpolicy.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 60vh;
}
.Slider-TextTC{
  top: 26%;
  left: 38%;
  position: absolute;
}
.TermsAndConditions{
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../src/Images/TermsAndConditions.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 60vh;
}
.Slider-TextP{
  top: 26%;
  left: 38%;
  position: absolute;
}
.privacy {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../src/Images/privcypolicy.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 60vh;
}


.contact {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../src/Images/Contact.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 90vh;
}

.Slider-TextC{
  top: 32%;
  left: 34%;
  position: absolute;
}

.contact-con .card{
  max-width: 90%;
  height: auto;
  /* background:  hsla(46, 100%, 50%, 0.493); */
  background:rgba(248, 174, 0, 0.5);
   /* opacity: .5; */
  /* backdrop-filter: blur(40px); */
}

.border_Right{
border-right: 2px solid #fff;
}

.bg-diff{
  background-color: #718ca1;
}

.bg-difft{
  background-color:#506c82;
}

textarea
{
  width:100%;
  background: transparent;
  outline: none;
  font-size: 24px;
  border:none;
  color: #ffffff;
}
.textwrapper
{
  border:2px solid rgb(240, 176, 24);
  margin:5px 0;
  padding:3px;
  outline: none;
}

input{
  width:100%;
  height:50px;
  background: transparent;
  border:2px solid rgb(240, 176, 24);
  color:#ffffff;
  outline: none;
  font-size: 18px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #ffffff;
  font-size: 18px;
  padding: 5px;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #ffffff;
  font-size: 18px;
  padding: 5px
}

.buttonColor{
  background-color: rgb(240, 176, 24);
  color:#ffffff;
  padding:10px 20px;
  font-size: 18px !important;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.buttonColor:hover{
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.5);
  transform: translateY(-5px);
}

.imgoverlaysWhite1 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 15%;
  height: auto;
  top: 0%;
  right: 0%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}
.imgoverlaysWhite2 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 15%;
  height: auto;
  top: 20%;
  right: 40%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}
.imgoverlaysWhite3 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 15%;
  height: auto;
  top: 40%;
  right: 80%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}
.imgoverlaysWhite4 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 15%;
  height: auto;
  top: 50%;
  right: 60%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}
.imgInfintityWhite img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 20%;
  height: auto;
  top: 40%;
  right: 5%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}
.imgInfintity img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 16%;
  height: auto;
  top: 5%;
  left: 5%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}

.imgoverlaysWhite5 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 15%;
  height: auto;
  top: 40%;
  right: 30%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}

.imgoverlaysWhite6 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 15%;
  height: auto;
  top: 2%;
  right: 0%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}

.imgoverlaysWhite7 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 15%;
  height: auto;
  top: 45%;
  left: 0%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}
.imgoverlaysWhite8 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 15%;
  height: auto;
  top: 58%;
  right: 0%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}
.imgoverlaysWhite9 img{
  /* background-image: url('../src/Images/square.svg'); */
  position: absolute;
  max-width: 15%;
  height: auto;
  top: -25%;
  right: 70%;
  /* clip: rect(0px,68px,140px,0px); */
  overflow-x: hidden;
  background-repeat: no-repeat;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .ImageDivSmall{
    width:48%;
  }

  .mobileTextSize {
    font-size: 1rem !important;
    width: 80% !important;
    }

  .imgoverlays3{
    width:10%
  }
  .imgoverlays4{
    width:10%
  }
  .border_Right{
    border-right: none;
    }
    .about-Us {
      padding: 0 0 0 1px;
    }

    .Mobile img{
      width: 280px;
      height: 75px
    }

    .carousel-control-prev-icon ,.carousel-control-next-icon{
      display:none;
    }
    .Slider-Text{
      left: 0;
    }
    .Slider-Text h3{
      font-size: 17px;
    }
    .Slider-Text p{
      font-size: 14px;
    }
    .imgoverlays7 img{
      left: 0%;
    }

    .Slider-TextC{
      left: 0;
    }
    .ImageDivSmall{
      text-align: left!important;
    }
    /* .manageddiv{
      justify-content: space-evenly;
    } */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 575px) {
  .ImageDivSmall{
    width:34%;
  }
  .imgoverlays3{
    width:10%
  }
  .imgoverlays4{
    width:10%
  }
  .about-Us {
    padding: 0 0 0 60px;
  }
  .Slider-Text p{
    font-size: 16px;
  }
  .Slider-Text {
    margin: 0 6rem;
    left:0;
  }
  .ImageDivSmall{
    text-align: left!important;
  }
  /* .border_Right{
    border-right: none;
    } */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .ImageDivSmall{
    width:45%;
  }
  .textb{
    font-size: 16px;
  }
  .textb > span{
    font-size: 15px;
  }
  .imgoverlays3{
    width:10%
  }
  .imgoverlays4{
    width:10%
  }
  .about-Us {
    padding: 0 0 0 120px;
  }

  .Slider-Text {
    margin: 0 12rem;
    left:0;
    /* text-align: center !important; */
  }
  /* .border_Right{
    border-right: none;
    } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* .textEb span{
    font-weight: 700;
    font-size: 14px;
    padding: 0;
    margin:2px;
  } */
  .ImageDivSmall{
    width:48%;
  }
  .textb{
    font-size: 16px;
  }
  .textb > span{
    font-size: 15px;
  }
  .imgoverlays3{
    width:10%
  }
  .imgoverlays4{
    width:10%
  }
   .about-Us {
    padding: 0 0 0 260px;
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .ImageDivSmall{
    width:40%;
  }
   .about-Us {
    padding: 0 0 0 320px;
  }
}